import { template as template_51402e9c147e4e2b87da259ab6e63a34 } from "@ember/template-compiler";
const WelcomeHeader = template_51402e9c147e4e2b87da259ab6e63a34(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
