import { template as template_a6f2daf00ffe43e5994620981debb48f } from "@ember/template-compiler";
const FKText = template_a6f2daf00ffe43e5994620981debb48f(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
