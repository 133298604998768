import { template as template_68c55be67d5a4382af4ac5e1ed9da740 } from "@ember/template-compiler";
const FKLabel = template_68c55be67d5a4382af4ac5e1ed9da740(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
