import { template as template_427ce9660e82411886be734752e0d526 } from "@ember/template-compiler";
const FKControlMenuContainer = template_427ce9660e82411886be734752e0d526(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
